import { createSelector } from 'reselect';
var getRoot = function (_a) {
    var utils = _a.utils;
    return utils;
};
export var getConfig = createSelector(getRoot, function (_a) {
    var config = _a.config;
    return config;
});
export var getClientId = createSelector(getConfig, function (config) { return config && config.security && config.security.clientId; });
export var getAuthSettings = createSelector(getConfig, function (config) { return config && config.authSettings; });
export var getCustomerDesignsUrl = createSelector(getConfig, function (config) { var _a; return (_a = config === null || config === void 0 ? void 0 : config['customer-designs']) === null || _a === void 0 ? void 0 : _a.api.url; });
export var getColleagueExperienceServiceUrl = createSelector(getConfig, function (config) { var _a; return (_a = config === null || config === void 0 ? void 0 : config['colleague-experience-service']) === null || _a === void 0 ? void 0 : _a.api.url; });
export var getRedirectUrl = createSelector(getConfig, function (config) { return config && (config === null || config === void 0 ? void 0 : config.redirectUrl); });
