import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["REDIRECT_AFTER_LOGIN_SUCCES"] = "@hip/colleague-designs/auth/REDIRECT_AFTER_LOGIN_SUCCESS";
    ActionTypes["VALIDATE_AUTH_SUCCESS"] = "@hip/colleague-designs/auth/VALIDATE_AUTH_SUCCESS";
    ActionTypes["VALIDATE_AUTH_FAIL"] = "@hip/colleague-designs/auth/VALIDATE_AUTH_FAIL";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    redirectAfterLoginSuccess: function () {
        return createAction(ActionTypes.REDIRECT_AFTER_LOGIN_SUCCES);
    },
    validateAuthSuccess: function (payload) {
        return createAction(ActionTypes.VALIDATE_AUTH_SUCCESS, payload);
    },
    validateAuthFail: function () { return createAction(ActionTypes.VALIDATE_AUTH_FAIL); },
};
