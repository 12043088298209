import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDesignsUrl } from '../../core/utils/selectors';
import styles from './featureTogglesDashboard.module.css';
export var FeatureTogglesDashboard = function () {
    var baseIFrameUrl = useSelector(getCustomerDesignsUrl);
    var configCDTUrl = React.useMemo(function () { return baseIFrameUrl + "/feature-toggles"; }, [
        baseIFrameUrl,
    ]);
    return (React.createElement("div", { className: styles.FeatureTogglesDashboard },
        React.createElement("iframe", { src: configCDTUrl, className: styles.FeatureTogglesDashboardFrame, style: { border: 'none' } })));
};
