import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["INIT"] = "@hip/utils/INIT";
    ActionTypes["SET_CONFIG"] = "@hip/utils/SET_CONFIG";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    init: function () { return createAction(ActionTypes.INIT); },
    setConfig: function (payload) {
        return createAction(ActionTypes.SET_CONFIG, payload);
    },
};
