import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { isProd, baseShareRoute, createPrintBomShareRoute, createShareRoute, createFeatureTogglesRoute, } from '@hip/helpers';
import { PrintedBom } from './containers/printedBom/printedBom';
import { DesignView } from './containers/designView/designView';
import { FeatureTogglesDashboard } from './containers/featureTogglesDashboard/featureTogglesDashboard';
import sagas from './sagas';
import { createRootReducers } from './reducers';
import './index.module.css';
var history = createBrowserHistory();
var sagaMiddleware = createSagaMiddleware();
var composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
});
var store = createStore(createRootReducers(history), isProd()
    ? applyMiddleware(routerMiddleware(history), sagaMiddleware)
    : composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
sagaMiddleware.run(sagas);
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(Provider, { store: store },
        React.createElement(ConnectedRouter, { history: history },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: createPrintBomShareRoute(baseShareRoute), component: PrintedBom }),
                React.createElement(Route, { exact: true, path: createShareRoute(baseShareRoute), component: DesignView }),
                React.createElement(Route, { exact: true, path: createFeatureTogglesRoute(), component: FeatureTogglesDashboard }),
                React.createElement(Route, { exact: true, path: "/" },
                    React.createElement(Redirect, { to: createShareRoute('') })))))), document.getElementById('root'));
