import * as queryString from 'query-string';
export var stringifyRoute = function (pathname, query) {
    if (query === void 0) { query = {}; }
    var stringifiedQuery = queryString.stringify(query);
    return pathname + (stringifiedQuery ? "?" + stringifiedQuery : '');
};
export var getCurrentOrigin = function () {
    return window ? window.location.origin : '';
};
export var getWindowLocation = function () { return window.location; };
