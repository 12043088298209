import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import hipCommunicator, { COMMUNICATOR_TYPES, EVENTS } from '@hip/communicator';
import { createBomShareRoute } from '@hip/helpers';
import { getAuthToken } from '../../../core/auth/selectors';
import { getCustomerDesignsUrl, getColleagueExperienceServiceUrl, } from '../../../core/utils/selectors';
import { createGetProductsRequest } from '../../../core/products/utils';
import styles from './bomModal.module.css';
export var BomModal = function (_a) {
    var close = _a.close;
    var bomIFrameRef = useRef(null);
    var shareId = useParams().shareId;
    var customerDesignsUrl = useSelector(getCustomerDesignsUrl);
    var iFrameUrl = "" + customerDesignsUrl + createBomShareRoute(shareId);
    var dispatch = useDispatch();
    var noop = function () { return null; };
    var showPrintedBom = function () {
        return dispatch(push("/3d-planner/share/" + shareId + "/bom/print"));
    };
    var colleagueExperienceServiceUrl = useSelector(getColleagueExperienceServiceUrl);
    var authToken = useSelector(getAuthToken);
    var isReady = Boolean(colleagueExperienceServiceUrl && authToken && iFrameUrl);
    useEffect(function () {
        if (isReady) {
            var loaded = function (communicator) {
                communicator(EVENTS.POST_AUTH, authToken);
            };
            var getProducts = createGetProductsRequest(colleagueExperienceServiceUrl, authToken);
            var hipClient_1 = hipCommunicator.init(iFrameUrl, bomIFrameRef.current, {
                loaded: loaded,
                getProducts: getProducts,
                printedBom: showPrintedBom,
                closeBom: close,
                addToBasket: noop,
            }, COMMUNICATOR_TYPES.BOM);
            return function () { return hipClient_1.unsubscribe(); };
        }
    }, [isReady]);
    return (React.createElement("div", { onClick: close, className: styles.ModalOverlay },
        React.createElement("div", { className: styles.Modal },
            React.createElement("iframe", { className: styles.Iframe, ref: bomIFrameRef, frameBorder: 0 }))));
};
