import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import hipCommunicator, { COMMUNICATOR_TYPES, EVENTS } from '@hip/communicator';
import { createGetProductsRequest } from '../../core/products/utils';
import { getColleagueExperienceServiceUrl, getCustomerDesignsUrl, } from '../../core/utils/selectors';
import { getAuthToken } from '../../core/auth/selectors';
import styles from './printedBom.module.css';
var getPrintedBomUrl = function (baseUrl, shareId) {
    return baseUrl + "/3d-planner/share/" + shareId + "/bom/print";
};
var noop = function () { return null; };
export var PrintedBom = function () {
    var shareId = useParams().shareId;
    var iFrameRef = useRef(null);
    var iFrameWrapperRef = useRef(null);
    var resized = function (contentHeight) {
        iFrameWrapperRef.current.style.height = contentHeight + "px";
        iFrameRef.current.style.height = contentHeight + "px";
    };
    var colleagueExperienceServiceUrl = useSelector(getColleagueExperienceServiceUrl);
    var authToken = useSelector(getAuthToken);
    var customerDesignsUrl = useSelector(getCustomerDesignsUrl);
    var iFrameUrl = getPrintedBomUrl(customerDesignsUrl, shareId);
    useEffect(function () {
        var isReady = Boolean(colleagueExperienceServiceUrl && authToken && iFrameUrl);
        if (isReady) {
            var getProducts = createGetProductsRequest(colleagueExperienceServiceUrl, authToken);
            var loaded = function (communicator) {
                communicator(EVENTS.POST_AUTH, authToken);
            };
            var hipClient_1 = hipCommunicator.init(iFrameUrl, iFrameRef.current, {
                loaded: loaded,
                resized: resized,
                getProducts: getProducts,
                // TODO: refresh
                refreshAuthToken: noop,
            }, COMMUNICATOR_TYPES.PRINT_BOM);
            return function () { return hipClient_1.unsubscribe(); };
        }
    }, [colleagueExperienceServiceUrl, authToken, iFrameUrl]);
    return (React.createElement("div", { ref: iFrameWrapperRef, className: styles.PrintedBomIFrameWrapper },
        React.createElement("iframe", { className: styles.PrintedBomIFrame, src: iFrameUrl, ref: iFrameRef, frameBorder: "0" })));
};
