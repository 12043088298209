import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import hipCommunicator, { COMMUNICATOR_TYPES, EVENTS, } from '@hip/communicator';
import { createShareRoute } from '@hip/helpers';
import { PROJECT_CATEGORY } from '@hip/iib-utils/models/external/project';
import { getCustomerDesignsUrl } from '../../core/utils/selectors';
import { BomModal } from '../../components/modals/bomModal/bomModal';
import { getAuthToken } from '../../core/auth/selectors';
import styles from './designView.module.css';
var getIFrameUrl = function (baseUrl, shareId) {
    if (!baseUrl) {
        return null;
    }
    var url = new URL("" + baseUrl + createShareRoute(shareId));
    url.searchParams.append('isPartOfColleagueApp', 'true');
    return url.toString();
};
export var DesignView = function () {
    var _a = useState(false), showBomModal = _a[0], setShowBomModal = _a[1];
    var iFrameRef = useRef(null);
    var baseIFrameUrl = useSelector(getCustomerDesignsUrl);
    var shareId = useParams().shareId;
    var iFrameUrl = getIFrameUrl(baseIFrameUrl, shareId);
    var authToken = useSelector(getAuthToken);
    var dispatch = useDispatch();
    var designCreated = function (data) {
        data.shareId && dispatch(replace(createShareRoute(data.shareId)));
    };
    var createNewDesign = function () {
        dispatch(push(createShareRoute('')));
        // TODO: Clean up this quick fix - HIP-4080
        location.reload();
    };
    // What to do on exit?
    var exit = function () {
        dispatch(push(createShareRoute('')));
        // TODO: fix this properly - HIP-4081
        location.reload();
    };
    var showBom = function () { return setShowBomModal(true); };
    // Using this for functionality not required by BricoDepot at this stage
    var noop = function () { return null; };
    var isReady = Boolean(iFrameUrl && authToken);
    useEffect(function () {
        if (isReady) {
            var loaded = function (communicator) {
                communicator(EVENTS.POST_AUTH, authToken);
                communicator(EVENTS.POST_CATEGORY, PROJECT_CATEGORY.KITCHEN);
            };
            var hipClient_1 = hipCommunicator.init(iFrameUrl, iFrameRef.current, {
                bom: showBom,
                designCreated: designCreated,
                exit: exit,
                createNewDesign: createNewDesign,
                loaded: loaded,
                navigateToAppointmentBooking: noop,
                // TODO
                refreshAuthToken: noop,
            }, COMMUNICATOR_TYPES.MXT);
            return function () {
                hipClient_1.unsubscribe();
            };
        }
    }, [isReady]);
    return (React.createElement("div", { className: styles.CDTIFrameWrapper },
        React.createElement("iframe", { ref: iFrameRef, className: styles.CDTIFrame, frameBorder: 0 }),
        showBomModal && React.createElement(BomModal, { close: function () { return setShowBomModal(false); } })));
};
