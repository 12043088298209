var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { chunk, flatten } from 'lodash';
import { CURRENCIES, OPCOS } from '@hip/interfaces';
import { getMarxentBomEans, hydrateMxtBom } from '@hip/helpers';
import { EVENTS } from '@hip/communicator';
import { fetchBDROProducts } from './requests';
export var createGetProductsRequest = function (baseUrl, authToken) { return function (communicator, mxtDesignBom) { return __awaiter(void 0, void 0, void 0, function () {
    var eans, batchedEans, responses, productDetails, foundEans_1, missingEans_1, missingProducts, convertedProducts, hydratedBom, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                eans = getMarxentBomEans(mxtDesignBom);
                batchedEans = chunk(eans, 100);
                return [4 /*yield*/, Promise.all(batchedEans.map(function (batch) { return fetchBDROProducts(baseUrl, authToken, batch); }))];
            case 1:
                responses = _a.sent();
                productDetails = flatten(responses);
                foundEans_1 = productDetails.map(function (product) { return product.ean; });
                missingEans_1 = eans.filter(function (ean) { return !foundEans_1.includes(ean); });
                missingProducts = mxtDesignBom.groups
                    .map(function (group) {
                    return group.assemblies.map(function (assembly) {
                        return assembly.products.filter(function (product) { return missingEans_1.includes(product.ean); });
                    });
                })
                    .flat(2);
                convertedProducts = productDetails.map(function (product) {
                    return convertBDROProduct(product);
                });
                hydratedBom = hydrateMxtBom(mxtDesignBom, convertedProducts, missingProducts, OPCOS.BDRO);
                return [2 /*return*/, communicator(EVENTS.POST_PRODUCTS, hydratedBom)];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, communicator(EVENTS.GET_PRODUCTS_FAIL)];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var convertBDROProduct = function (product) {
    return {
        type: 'product',
        id: product.ean,
        attributes: {
            categoryIds: undefined,
            categoryNames: undefined,
            contactCentreOrderingOnly: undefined,
            deliveryChargeIdentifier: undefined,
            description: product.description,
            ean: product.ean,
            featureSummary: undefined,
            features: undefined,
            fulfilmentOptions: undefined,
            healthAndSafety: undefined,
            infoBullets: undefined,
            mediaObjects: [{ url: product.image }],
            name: product.shortName,
            pricing: {
                currentPrice: { amountIncTax: product.price.incVat },
                currencyCode: CURRENCIES.RON,
            },
            primaryCategory: undefined,
            primaryCategoryId: undefined,
            primaryCategoryName: undefined,
            reviewsUrl: undefined,
            shortName: product.shortName,
            sundayDeliveryAvailable: undefined,
            technicalSpecifications: undefined,
            url: product.pdpUrl,
            pdpURL: product.pdpUrl,
        },
    };
};
