import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as authReducer } from './core/auth/reducer';
import { reducer as utilsReducer } from './core/utils/reducer';
export var createRootReducers = function (history) {
    return combineReducers({
        auth: authReducer,
        router: connectRouter(history),
        utils: utilsReducer,
    });
};
